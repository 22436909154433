@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    background-color: #2F2B36;
}
.beta{
    font-size: 10px;
}
